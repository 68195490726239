<template>
  <div style="padding-bottom: 48px">
    <v-snackbar
      v-model="displayErr"
      :top="true"
      class="form-error"
      :timeout="0"
      type="error"
    >
      {{ errorMessage }}
      <v-btn dark flat @click="displayErr = false">Close</v-btn>
    </v-snackbar>
    <BaseForm
      ref="form"
      :disabled="disabled"
      :form-type="formType"
      :layout="layout"
      :loading="loading"
      :row="row"
    >
      <template #bottom>
        <div class="button-container">
          <v-btn
            v-if="!isModeMyCompany"
            class="btn-secondaryaction"
            @click="cancelAction"
          >
            Cancel
          </v-btn>

          <v-btn
            :disabled="loading"
            :loading="loading"
            class="btn-primaryaction"
            @click="
              $router.push({
                name: 'company-checkout-page.view',
                params: { id: id },
              })
            "
          >
            Branding
          </v-btn>

          <v-btn
            v-if="isModeView"
            class="btn-primaryaction"
            @click="enableEdit"
          >
            Edit Company
          </v-btn>

          <v-btn
            v-if="isModeEdit"
            :disabled="loading"
            :loading="loading"
            class="btn-primaryaction"
            @click="submit"
          >
            Save Changes
          </v-btn>

          <v-btn
            v-if="isModeAdd"
            :disabled="loading"
            :loading="loading"
            class="btn-primaryaction"
            @click="submit"
          >
            Add
          </v-btn>
        </div>
      </template>
    </BaseForm>
  </div>
</template>

<script>
import BaseForm from '@/components/BaseForm.vue'
import { toCamelCase } from '@/utils/string'
import { isNotEmpty, isRequired } from '@/utils/validators'
import { schema } from '@/components/CheckoutSchema'
import { authComputed } from '@/state/helpers'
import companies from '@/services/companies'

const companyMapping = [
  'dark_logo_url',
  'light_logo_url',
  'email',
  'social_links',
  'is_broker',
]

export default {
  name: 'CheckoutForm',
  components: {
    BaseForm,
  },
  props: {
    id: {
      type: String,
      default: () => {
        return null
      },
    },
    mode: {
      type: String,
      default: () => 'view',
    },
  },
  data() {
    return {
      loading: false,
      disabled: true,
      displayErr: false,
      errorMessage: '',
      row: {},
      layout: [],
      formType: '',
    }
  },
  computed: {
    ...authComputed,
    isModeAdd() {
      return this.mode === 'add'
    },
    isModeEdit() {
      return this.mode === 'edit'
    },
    isModeView() {
      return this.mode === 'view'
    },
    isModeMyCompany() {
      return this.mode === 'my-company'
    },
  },
  async mounted() {
    this.disabled = !!(this.isModeView || this.isModeMyCompany)
    let company = schema

    if (!this.isModeAdd) {
      try {
        const data = await companies.getCheckoutPage({
          pageId: this.id,
        })
        company = data.data
      } catch (e) {
        this.displayErrMsg(e, true)
        return
      }
    }
    this.row = company

    this.formType = this.row.name || 'Add Checkout Page'

    this.layout = [
      {
        id: 1,
        fields: [
          {
            key: 'name',
            label: 'Company Name',
            type: 'text',
            rules: [
              isRequired(true, isNotEmpty, {
                req: 'Company Name Required',
                error: 'Company Name Required',
              }),
            ],
          },
          {
            label: 'address',
            type: 'slot',
          },
          {
            key: 'internalName',
            label: 'Internal Name',
            type: 'text',
            color: 'primary',
            forceDisabled: this.isModeEdit,
            tooltip:
              "A checkout page's interal name identifies it within the system and will never be shown to the user. Once it is set it cannot be changed.",
            rules: [
              isRequired(true, isNotEmpty, {
                req: 'Internal Name Required',
                error: 'Internal Name Required',
              }),
            ],
          },
          {
            key: 'domain',
            label: 'Domain',
            type: 'text',
            color: 'primary',
            tooltip: 'Example: https://checkout.example.com',
            rules: [
              isRequired(true, isNotEmpty, {
                req: 'Domain Required',
                error: 'Domain Required',
              }),
            ],
          },

          {
            key: 'primaryEmailAddress',
            label: 'Primary Email Address',
            type: 'text',
            color: 'primary',
            tooltip:
              'All transactional emails will be sent from this email address and it will be used as the main contact email address.',
            rules: [
              isRequired(true, isNotEmpty, {
                req: 'Email Required',
                error: 'Email Required',
              }),
            ],
          },
          {
            key: 'emailSenderName',
            label: 'Email Sender Name',
            type: 'text',
            color: 'primary',
            tooltip: 'All transactional emails will be sent using this name.',
            rules: [
              isRequired(true, isNotEmpty, {
                req: 'Email Sender Name Required',
                error: 'Email Sender Name Required',
              }),
            ],
          },
          {
            key: 'headerUrl',
            label: 'Header Url',
            type: 'text',
            color: 'primary',
            tooltip:
              'This url will be substituted for the normal CoachRail header on checkout pages.',
            rules: [
              isRequired(true, isNotEmpty, {
                req: 'Header Url Required',
                error: 'Header Url Required',
              }),
            ],
          },
          {
            key: 'terms',
            label: 'Terms & Conditions',
            type: 'text-box',
            color: 'primary',
            readOnly: true,
            rows: 20,
            tooltip:
              'These terms will be substituted for your normal Company terms on checkout pages.',
          },
        ],
      },
    ]
  },
  methods: {
    disableAndRevert() {
      this.disabled = true
      this.mode = 'view'
      this.$router.push(`/checkout-pages/view/${this.id}`)
    },
    displayErrMsg(e, status) {
      this.displayErr = status
      this.errorMessage = e
    },
    async submit() {
      this.loading = true
      let reply = {}

      const test = this.$refs.form.validateAll()
      if (test.length > 0) {
        this.loading = false
        this.displayErrMsg(
          'Some fields are missing that may be Required. or are invalid.',
          true
        )
        return
      }

      const submissionRow = JSON.parse(JSON.stringify(this.row))
      const legacyMappings = this.isModeAdd
        ? companyMapping.reduce((accumulator, legacyKey) => {
            accumulator[toCamelCase(legacyKey)] = this.currentUser.company[
              legacyKey
            ]
            return accumulator
          }, {})
        : {}

      submissionRow.companyId = this.currentUser.companyId
      submissionRow.primaryEmailName = submissionRow.emailSenderName

      const finalSubmission = Object.assign(legacyMappings, submissionRow)

      try {
        const params = {
          pageId: this.id,
          payload: finalSubmission,
        }
        if (this.isModeAdd) {
          reply = await companies.addCheckoutPage(params)
        } else {
          reply = await companies.modifyCheckoutPage(params)
        }
      } catch (e) {
        this.loading = false
        this.disabled = !this.disabled

        this.displayErrMsg(e, true)
        return
      }

      this.loading = false
      this.disabled = !this.disabled

      this.$router.push(
        `/checkout-pages/view/${reply.data ? reply.data : this.id}`
      )
    },
    enableEdit() {
      this.disabled = !this.disabled
      this.mode = 'edit'
      this.$router.push(`/checkout-pages/edit/${this.id}`)
    },
    backToTable() {
      this.$router.push('/checkout-pages')
    },
    cancelAction() {
      if (this.isModeEdit) {
        this.disableAndRevert()
        return
      }

      this.backToTable()
    },
  },
}
</script>

<style lang="scss" scoped>
.form-error {
  color: $red;
}

.button-container {
  position: relative;
  right: 1.3%;
  bottom: 10px;
  display: flex;
  flex-direction: row;
}

::v-deep .v-card__title {
  padding: 16px 16px 16px 0;
}

::v-deep input:disabled {
  background-color: $blue-dull;
}

::v-deep .card {
  position: relative;
  flex-direction: column;
  padding: 24px;
  background: $white;
  border-radius: 15px;
}

::v-deep .empty {
  height: 80px;
}

::v-deep .fax {
  width: 50%;
}

::v-deep .sheet {
  padding: 0 70px 30px 70px;
  margin-bottom: 0;
  border-radius: 0 0 15px 15px;
}

::v-deep .container {
  padding: 0 24px 0 24px;
}

::v-deep label.v-label.theme--light {
  width: 140px;
}

::v-deep .row-block {
  max-width: 45%;
}

::v-deep textarea {
  overflow-y: scroll;
  resize: 0;
}
</style>
