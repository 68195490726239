export const schema = {
  companyId: '',
  name: '',
  internalName: '',
  domain: '',
  primaryEmailAddress: '',
  primaryEmailName: '',
  emailSenderName: '',
  headerUrl: '',
  terms: '',
}
